import React from "react"
import { StaticQuery, graphql } from "gatsby"

const ComponentServices = () => (
  <StaticQuery
    query={graphql`
      query {
        allWpService(sort: { fields: [date], order: DESC }) {
          edges {
            previous {
              id
            }
            post: node {
              title
              featuredImage {
                node {
                  altText
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
              ACF_Services {
                copy
                additionalCopy {
                  heading
                  details
                }
                icon {
                  altText
                  localFile {
                    publicURL
                  }
                }
              }
            }
            next {
              id
            }
          }
        }
      }
    `}
    render={data => <pre>{JSON.stringify(data, null, 4)}</pre>}
  ></StaticQuery>
)

export default ComponentServices
